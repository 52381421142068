import React from 'react'

import FormSuccessTemplate from '../../../components/templates/FormSuccessTemplate'

const LotSuccess = () => {
  return (
    <div>
      <FormSuccessTemplate
        title="Succès - Demande d'information d'un lot"
        description="Votre demande d'information pour un lot a bien été envoyée !" />
    </div>
  )
}

export default LotSuccess
